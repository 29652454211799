import { requestApi } from '@/assets/modules/request'
import store from '@/store'

/**
 * 公開JSON取得APIからデータを取得する
 * @param {string} id
 * @param {string} prefix
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/api').ApiResponse>>}
 */
export async function getJsonFile(id, prefix, suffix = '', roomId = 0) {
  const [facilityId, sectionId] = id
    .replace(/(\d{8})(\d{8})(\d{8})?/, '$1,$2')
    .split(',')

  const response = await requestApi.get(
    `/facilities/${facilityId}/shops/${sectionId}/rooms/${roomId}/jsonFile`,
    {
      headers: {
        Authorization: store.getters['auth/idToken'],
      },
      params: {
        jsonFile: `${prefix}${id}${suffix}.json`,
      },
    }
  )

  return response
}
