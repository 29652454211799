<template>
  <PSignupForm @submit="handleSubmit">
    <template slot="title">
      {{ $t('セキュリティコード登録') }}
    </template>
    <template slot="contents">
      <p class="p-authSection__lead">
        {{
          $t(
            'アカウントとの紐付けのため、管理者よりご案内されているセキュリティコードを登録してください。'
          )
        }}
      </p>
      <div class="p-authInputs">
        <PAuthInput
          class="p-authInputs__input"
          v-model.trim="$v.code.$model"
          :isError="$v.code.$error"
          :placeholder="$t('セキュリティコード')"
        />
        <p
          v-if="statusCode"
          class="p-authInputs__errorText"
          v-t="getErrorMessage(statusCode)"
        />
      </div>
      <button
        type="submit"
        class="p-authSection__button c-button"
        v-t="'登録する'"
        :disabled="$v.$invalid"
      />
    </template>
  </PSignupForm>
</template>

<script>
import PAuthInput from '@/components/auth/PAuthInput.vue'
import PSignupForm from '@/components/auth/PSignupForm.vue'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AuthPresentation',
  components: {
    PAuthInput,
    PSignupForm,
  },
  props: {
    statusCode: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      code: '',
    }
  },
  validations: {
    code: {
      required,
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('postSecurityCode', {
        code: this.code,
      })
    },
    getErrorMessage(statusCode) {
      switch (statusCode) {
        case 204:
          return 'すでにユーザ登録は完了しています。ログイン画面からログインしてください。'
        case 403:
          return 'ユーザ登録できませんでした。QRコード（URL)、セキュリティコードを再度確認してください。'
        default:
          return {
            path: 'ユーザー登録時にエラーが発生しました。({statusCode})',
            args: { statusCode },
          }
      }
    },
  },
}
</script>
