import { requestApi } from '@/assets/modules/request'
import store from '@/store'

/**
 * 公開センサー一覧APIからセンサ一覧情報データを取得する
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/sensorList').SensorListApi>>}
 */
export async function getSensorList(id) {
  const [facilityId, sectionId] = id
    .replace(/(\d{8})(\d{8})/, '$1,$2')
    .split(',')

  const response = await requestApi.get(
    `/facilities/${facilityId}/shops/${sectionId}/rooms`,
    {
      headers: {
        Authorization: store.getters['auth/idToken'],
      },
    }
  )

  return response
}
