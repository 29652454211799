import { requestJson } from '@/assets/modules/request'

/**
 * 店舗/学校/病院情報を取得する
 * JSON名: 'T' + {id} + '.json'
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/shopData').ShopDataOriginal>>}
 */
export function getShopDataJson(id) {
  return requestJson.get(`/T${id}.json`)
}
