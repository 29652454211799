import dayjs from 'dayjs'

const DataDefineMap = {
  co2Concentration: {
    label: 'CO2濃度',
    maxThreshold: 1500,
    averageToInt: true,
    statusCalculator(value) {
      if (value >= 1500) return { status: '換気が必要', level: 'attention' }
      if (value > 1000) return { status: 'やや換気が必要', level: 'alert' }
      return { status: 'よい.CO2濃度', level: 'clean' }
    },
    yAxisMaxValue: 2000,
  },
  temperature: {
    label: '室温',
    maxThreshold: 29,
    averageToInt: false,
    statusCalculator(value) {
      if (value >= 29) return { status: '高い.室温', level: 'attention' }
      if (value < 17) return { status: '低い.室温', level: 'attention' }
      return { status: 'よい.室温', level: 'clean' }
    },
    yAxisMaxValue: 40,
  },
  humidity: {
    label: '湿度',
    maxThreshold: 71,
    averageToInt: false,
    statusCalculator(value) {
      if (value >= 71) return { status: '高い.湿度', level: 'attention' }
      if (value < 40) return { status: '乾燥している', level: 'attention' }
      return { status: 'よい.湿度', level: 'clean' }
    },
    yAxisMaxValue: 100,
  },
  noise: {
    label: '騒音',
    maxThreshold: 80,
    averageToInt: true,
    statusCalculator(value) {
      if (value >= 80) return { status: '騒がしい', level: 'attention' }
      if (value >= 60) return { status: 'にぎやか', level: 'alert' }
      if (value < 40) return { status: '静か', level: 'clean' }
      return { status: 'ふつう', level: 'clean' }
    },
    yAxisMaxValue: 100,
  },
  bluetooth: {
    label: '混雑指数',
    maxThreshold: 90,
    averageToInt: true,
    statusCalculator(value) {
      if (value >= 90) return { status: '混雑', level: 'attention' }
      if (value >= 70) return { status: 'やや混雑', level: 'alert' }
      return { status: 'よい.混雑指数', level: 'clean' }
    },
    yAxisMaxValue: 100,
  },
  wbgt: {
    label: '暑さ指数',
    maxThreshold: 31,
    averageToInt: false,
    statusCalculator(value) {
      if (value >= 31) return { status: '危険.暑さ指数', level: 'attention' }
      if (value >= 28) return { status: '厳重警戒.暑さ指数', level: 'attention' }
      if (value >= 25) return { status: '警戒.暑さ指数', level: 'attention' }
      if (value >= 21) return { status: '注意.暑さ指数', level: 'alert' }
      return { status: 'ほぼ安全.暑さ指数', level: 'clean' }
    },
    yAxisMaxValue: 40,
  },
}

const DataOrderMap = [
  'co2Concentration',
  'temperature',
  'humidity',
  'noise',
  'bluetooth',
  'wbgt',
]

export const iconNameMap = {
  attention: 'faceFrowning',
  alert: 'faceNeutral',
  clean: 'faceSmiling',
}

export const fillColorMap = {
  attention: 'attention',
  alert: 'alert',
  clean: 'clean',
}

export const rangeMap = {
  '3hours': {
    datasetName: 'original',
    xAxisUnit: 'hour',
    range: {
      start(renderedAt) {
        return dayjs(renderedAt).subtract(3, 'hour').toISOString()
      },
    },
  },
  '1day': {
    datasetName: 'original',
    xAxisUnit: 'hour',
    range: {
      start(renderedAt) {
        return dayjs(renderedAt).subtract(24, 'hour').toISOString()
      },
    },
  },
  '1week': {
    datasetName: 'average',
    xAxisUnit: 'day',
    range: {
      start(renderedAt) {
        return dayjs(renderedAt)
          .subtract(6, 'day')
          .hour(0)
          .minute(0)
          .toISOString()
      },
      end(renderedAt) {
        return dayjs(renderedAt).toISOString()
      },
    },
  },
}

export class SensorDataViewModel {
  constructor(data) {
    /** @type {import("@/assets/request/types/sensorData").SensorDataOriginal} */
    this._data = data
    /** @type {string | null} */
    this._sensorId = null
    /** @type {string | null} */
    this._roomId = null
  }

  get sensorId() {
    return this._sensorId
  }

  set sensorId(sensorId) {
    this._sensorId = sensorId
  }

  get roomId() {
    return this._roomId
  }

  set roomId(roomId) {
    this._roomId = roomId
  }

  get data() {
    return this._data.iotDataList
  }

  get sensorData() {
    return DataOrderMap.reduce((result, key) => {
      /** @type {import("@/assets/request/types/sensorData").SensorData | undefined} */
      const data = this.data[key]

      if (!data) {
        result.push({
          label: DataDefineMap[key].label,
        })
        return result
      }

      const dataList = data.dataList
      const {
        label,
        maxThreshold,
        averageToInt,
        statusCalculator,
        yAxisMaxValue,
      } = DataDefineMap[key]
      const averageDataset = this.generateAverageDataset(dataList, averageToInt)

      // 5分間隔の最新データ取得
      const normalizedLatestData = dataList[dataList.length - 1] || {
        value: null,
        timestamp: null,
      }

      const normalizedAverageLatestData = averageDataset[
        averageDataset.length - 1
      ] || {
        y: null,
        x: null,
      }

      const suggestedMax = (Math.round(maxThreshold * 10) * 11) / 100

      return [
        ...result,
        {
          label,
          suggestedMax,
          unit: data.unit || '',
          latestStatus: {
            original: {
              ...statusCalculator(normalizedLatestData.value),
              value: normalizedLatestData.value,
              timestamp: normalizedLatestData.timestamp,
            },
            average: {
              ...statusCalculator(normalizedAverageLatestData.y),
              value: normalizedAverageLatestData.y,
              timestamp: normalizedAverageLatestData.x,
            },
          },
          dataList: {
            original: dataList.map(({ value: y, timestamp: x }) => ({ y, x })),
            average: averageDataset,
          },
          yAxisMaxValue,
        },
      ]
    }, [])
  }

  /**
   * @param {import("@/assets/request/types/sensorList").SensorListItem[]} data
   * @param {boolean} [toInt=true]
   * @memberof SensorDataViewModel
   */
  generateAverageDataset(data, toInt = true) {
    const dataMap = data.reduce(
      (result, { value, timestamp: _timestamp }) => {
        const timestamp = dayjs(_timestamp)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toISOString()

        const index = result.timestamp.indexOf(timestamp)
        if (index === 0) {
          return result
        } else if (index === -1) {
          result.timestamp.push(timestamp)
          result.value.push([value])
        } else {
          result.value[index].push(value)
        }

        return result
      },
      { timestamp: [], value: [] }
    )

    return dataMap.timestamp.map((timestamp, index) => {
      const value = dataMap.value[index]
      const sum = value.reduce((sum, value) => sum + value, 0)
      const result = toInt
        ? Math.round(sum / value.length)
        : Math.round((sum * 10) / value.length) / 10

      return {
        x: timestamp,
        y: result,
      }
    })
  }
}
