<template>
  <AuthPresentation
    @postSecurityCode="handlePostSecurityCode"
    :status-code="statusCode"
  />
</template>

<script>
import AuthPresentation from '@/components/auth/AuthPresentation.vue'
import store from '@/store'
import { postSecurityCode } from '@/assets/request'

export default {
  name: 'Auth',
  components: { AuthPresentation },
  metaInfo() {
    return {
      title: this.$t('セキュリティコード登録'),
    }
  },
  data() {
    return {
      statusCode: null,
    }
  },
  async beforeRouteEnter(to, _from, next) {
    const isLoggedIn = store.getters['auth/isLoggedIn']
    const { facilityId, sectionId, roomId } = to.query
    const { portalId } = to.params

    // 未ログインの場合ログイン画面ににリダイレクト
    if (!isLoggedIn) {
      next({
        name: 'Login',
        query: {
          isSecurityUserAccess: true,
          redirect: to.fullPath,
        },
        params: { portalId },
      })
      return
    }

    if (!facilityId || !sectionId || !roomId) {
      next({
        name: 'Index',
        params: { portalId },
      })
    }

    next()
  },
  methods: {
    async handlePostSecurityCode({ code }) {
      const { facilityId, sectionId, roomId } = this.$route.query

      const result = await postSecurityCode(facilityId, sectionId, roomId, code)

      if (result.isError) {
        this.statusCode = result.status
        return
      }

      this.$router.push({
        name: 'Index',
        params: { portalId: this.portalId },
      })
    },
  },
}
</script>
