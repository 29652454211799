import { getJsonFile } from './getJsonFile'

/**
 * 公開JSON取得APIから店舗/学校/病院情報データを取得する
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/shopData').ShopDataOriginal>>}
 */
export function getShopData(id) {
  return getJsonFile(id, 'T')
}
