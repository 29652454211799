import { requestJson } from '@/assets/modules/request'

/**
 * 第三者確認情報を取得する
 * JSON名: 'KC' + {id} + '.json'
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/shopConfirmList').ShopConfirmListOriginal').>>}
 */
export function getShopConfirmListJson(id) {
  return requestJson.get(`/KC${id}.json`)
}
