import { requestPublicApi } from '@/assets/modules/request'

/**
 * アクセス可否判定APIからデータを取得する
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/accessDecision').AccessDecisionApi>>}
 */
export function getAccessDecision(id) {
  return requestPublicApi.get('/accessDecision', {
    params: {
      pageUrl: id,
    },
  })
}
