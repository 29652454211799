import { requestJson } from '@/assets/modules/request'

/**
 * センサ一覧情報を取得する
 * JSON名: 'R' + {id} + '.json'
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/sensorList').SensorListOriginal>>}
 */
export function getSensorListJson(id) {
  return requestJson.get(`/R${id}.json`)
}
