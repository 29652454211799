<template>
  <article class="l-container">
    <div class="p-authContainer">
      <component
        :is="tag"
        class="p-authSection"
        @submit.prevent="$emit('submit')"
        v-bind="attrs"
      >
        <h2 class="p-authSection__title p-authSection__title--mobileLeft">
          <slot name="title" />
        </h2>
        <slot name="contents"></slot>
      </component>
    </div>
  </article>
</template>

<script>
export default {
  name: 'PSignupForm',
  props: {
    tag: {
      type: String,
      default: 'form',
    },
    novalidate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    attrs() {
      return {
        novalidate: this.tag === 'form' ? this.novalidate : false,
      }
    },
  },
}
</script>
