import { requestJson } from '@/assets/modules/request'
import { SensorDataViewModel } from '../viewModel/SensorDataViewModel'

/**
 * センサ情報を取得する
 * JSON名: 'I' + {id} + {sensorId} + '.json'
 * @param {string} id
 * @param {string} sensorId
 * @returns {Promise<import('@/assets/modules/request/Response').Response<import('@/assets/request/types/sensorData').SensorDataOriginal>>}
 */
export async function getSensorDataJson(id, sensorId) {
  const response = await requestJson.get(`/I${id}${sensorId}.json`)
  response.toViewModel(SensorDataViewModel)
  if (!response.isError) {
    response.data.sensorId = sensorId
  }
  return response
}
