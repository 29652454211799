import { SensorDataViewModel } from '../viewModel/SensorDataViewModel'
import { getJsonFile } from './getJsonFile'

/**
 * 公開JSON取得APIからセンサ情報データを取得する
 * @param {string} id
 * @returns {Promise<import('@/assets/modules/request/ApiResponse').ApiResponse<import('@/assets/request/types/sensorData').SensorDataApi>>}
 */
export async function getSensorData(id, sensorId, roomId) {
  const response = await getJsonFile(id, 'I', sensorId, roomId)
  response.toViewModel(SensorDataViewModel)
  if (!response.isError) {
    response.data.sensorId = sensorId
    response.data.roomId = roomId
  }
  return response
}
