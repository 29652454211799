<template>
  <input
    :type="type"
    class="input"
    :class="classList"
    :value="value"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'PAuthInput',
  props: {
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return ['text', 'number', 'password', 'email'].includes(value)
      },
    },
    value: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classList() {
      return {
        'input--error': this.isError,
      }
    },
  },
}
</script>
